// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lD3tG,.jHwse{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;row-gap:16px}.rzhoZ{font-size:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signWrapper": `lD3tG`,
	"wrapper": `jHwse`,
	"dialog": `rzhoZ`
};
module.exports = ___CSS_LOADER_EXPORT___;
