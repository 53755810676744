import { useConnect, useDisconnect } from 'components/Wallet/hooks';
import { Chain, EVMChainId } from 'types/emoney/Chain/indexV2';
import { Connector, useConnect as useWagmiConnect } from 'wagmi';
import ButtonGroup from '../../Buttons/ButtonGroup';
import WalletOption from './EvmOptions';

const connectorOrder = [
  'metaMask',
  'injected',
  'walletConnect',
  'coinbaseWalletSDK',
  'safe',
];

const sortConnectors = (connectors: readonly Connector[]) => {
  return connectors
    .filter(() => {
      return true;
    })
    ?.sort((a, b) => {
      // sort connectors
      const indexA = connectorOrder.indexOf(a.id);
      const indexB = connectorOrder.indexOf(b.id);

      // If the connector id is not in the predefined order, place it at the end
      const orderA = indexA === -1 ? connectorOrder.length : indexA;
      const orderB = indexB === -1 ? connectorOrder.length : indexB;

      return orderA - orderB;
    });
};

const EvmSelect = ({
  chain,
  handleSuccess,
}: {
  chain: Chain;
  handleSuccess: (wallet: string) => void;
}) => {
  const { connectors } = useWagmiConnect();
  const { connect } = useConnect('evm');
  const { disconnect } = useDisconnect('evm');

  const sortedConnectors = sortConnectors(connectors);

  return (
    <>
      <ButtonGroup>
        {sortedConnectors.map((connector) => {
          return (
            <WalletOption
              key={connector.uid}
              connector={connector}
              onClick={async () => {
                // Disconnecting before connecting makes it easier to manage.
                await disconnect();

                await connect(
                  { connector, chainId: chain?.chainId as EVMChainId },
                  {
                    onSuccess: () => handleSuccess(connector.id),
                    onError: async (e: Error) => {
                      console.error('Could not connect: ', e?.message);
                    },
                  },
                );
              }}
            />
          );
        })}
      </ButtonGroup>
    </>
  );
};
export default EvmSelect;
