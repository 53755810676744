import { useQuery } from '@tanstack/react-query';
import { useNotification } from 'components/Notification/hooks';
import { getKeys } from 'customer/cacheKeys';
import { useEffect } from 'react';
import { EVMChainId } from 'types/emoney/Chain/indexV2';
import { useAccount as useWagmiAccount } from 'wagmi';
import { isSafe } from '../evm/safe';

export const useIsSafe = (address: string, chainId: EVMChainId) => {
  const { notifyError } = useNotification();
  const { data, error } = useQuery({
    queryKey: getKeys.isSafe(address, chainId),
    queryFn: () => isSafe(address as string, chainId as number),
    enabled: !!address && !!chainId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
  });

  useEffect(() => {
    if (error) notifyError(error?.message);
  }, [error]);

  return !!data;
};

export const useIsConnectedSafe = () => {
  const { notifyError } = useNotification();
  const { address: connectedAddress, chainId: connectedChainId } =
    useWagmiAccount();

  const { data, error } = useQuery({
    queryKey: getKeys.isSafe(
      connectedAddress as string,
      connectedChainId as number,
    ),
    queryFn: () =>
      isSafe(connectedAddress as string, connectedChainId as number),
    enabled: !!connectedAddress && !!connectedChainId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  useEffect(() => {
    if (error) notifyError(error?.message);
  }, [error]);

  return !!data;
};
