import { ButtonProps } from 'components/Button/types';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Button.module.less';

const Button = (props: ButtonProps & { icon?: string }) => {
  const { icon, children, ...rest } = props;
  useStyles(s);
  return (
    <button type="button" className={s.button} {...rest}>
      {icon && <img src={icon} style={{ height: '42px', width: '42px' }} />}
      {children}
    </button>
  );
};
export default Button;
