import {
  BlockExplorerUrlType,
  Chain,
  ChainId,
  ChainName,
  Network,
} from 'src/types/emoney/Chain';
import { avalanche, avalancheFuji } from 'viem/chains';

import evmChains, {
  amoy as amoyV2,
  arbitrumSepolia as arbitrumSepoliaV2,
  arbitrum as arbitrumV2,
  chiado as chiadoV2,
  ethereumLocal as ethereumLocalV2,
  ethereum as ethereumV2,
  gnosis as gnosisV2,
  polygon as polygonV2,
  sepolia as sepoliaV2,
} from 'components/Wallet/evm/chains';

let IS_SANDBOX = false;
let IS_PRODUCTION = false;
if (typeof window !== 'undefined' && window?.App) {
  IS_SANDBOX = window.App?.environment === 'sandbox';
  IS_PRODUCTION = window.App?.environment === 'production';
}

export const chainSortOrder: ChainName[] = [
  'gnosis',
  'noble',
  'arbitrum',
  'avalanche',
  'polygon',
  'ethereum',
];

export const isEvmChainId = (chainId?: number): boolean => {
  return chainId ? !!evmChains.find((chain) => chain.id === chainId) : false;
};

/**
 * Chain definitions for RainbowKit/wagmi
 */
export const ethereumLocal: Chain = {
  ...ethereumLocalV2,
  moneriumId: {
    chain: 'ethereum',
    network: 'local',
  },
};

export const polygonAmoy: Chain = {
  ...amoyV2,
  moneriumId: {
    chain: 'polygon',
    network: 'amoy',
  },
};
export const noble: Chain = {
  name: 'Noble',
  nativeCurrency: { name: 'USDC', symbol: 'USDC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://noble-rpc.polkachu.com/'],
    },
    public: {
      http: ['https://noble-rpc.polkachu.com/'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Noble Explorer',
      url: 'https://www.mintscan.io/noble',
    },
  },
  testnet: true,
  iconUrl: '/assets/emoney/chains/noble-bgfill-icon.svg',
  moneriumId: {
    chain: 'noble',
  },
};
export const nobleFlorin: Chain = {
  name: 'Noble Florin devnet',
  nativeCurrency: { name: 'USDC', symbol: 'USDC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.florin.noble.xyz'],
    },
    public: {
      http: ['https://rpc.florin.noble.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Noble Explorer',
      url: 'https://explorer.florin.noble.xyz/florin',
    },
  },
  testnet: true,
  iconUrl: '/assets/emoney/chains/noble-bgfill-icon.svg',
  moneriumId: {
    chain: 'noble',
  },
};
export const nobleGrand: Chain = {
  name: 'Noble Grand',
  nativeCurrency: { name: 'USDC', symbol: 'USDC', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://testnet-rpc.noble.xyz'],
    },
    public: {
      http: ['https://testnet-rpc.noble.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Noble Explorer',
      url: 'https://www.mintscan.io/noble-testnet',
    },
  },
  testnet: true,
  iconUrl: '/assets/emoney/chains/noble-bgfill-icon.svg',
  moneriumId: {
    chain: 'noble',
  },
};

export const arbitrum: Chain = {
  ...arbitrumV2,
  moneriumId: {
    chain: 'arbitrum',
    network: 'mainnet',
  },
};
export const arbitrumSepolia: Chain = {
  ...arbitrumSepoliaV2,
  iconUrl: '/assets/emoney/chains/arbitrum-icon.svg',
  moneriumId: {
    chain: 'arbitrum',
    network: 'sepolia',
  },
};

export const mainnet: Chain = {
  ...ethereumV2,
  moneriumId: {
    chain: 'ethereum',
    network: 'mainnet',
  },
};

export const gnosis: Chain = {
  ...gnosisV2,
  moneriumId: {
    chain: 'gnosis',
    network: 'mainnet',
  },
};

export const gnosisChiado: Chain = {
  ...chiadoV2,
  moneriumId: {
    chain: 'gnosis',
    network: 'chiado',
  },
};
export const polygon: Chain = {
  ...polygonV2,
  moneriumId: {
    chain: 'polygon',
    network: 'mainnet',
  },
};

export const sepolia: Chain = {
  ...sepoliaV2,
  name: 'Ethereum Sepolia',
  moneriumId: {
    chain: 'ethereum',
    network: 'sepolia',
  },
};

export const localhostChains = [ethereumLocal];
export const testnetChains = [
  gnosisChiado,
  polygonAmoy,
  arbitrumSepolia,
  sepolia,
];

export const productionChains = [gnosis, polygon, mainnet];

/**
 * @deprecated remove references
 */
export const splitMoneriumChainId = (
  moneriumChainId: ChainId,
): { chain: ChainName; network: Network } => {
  if (!moneriumChainId) throw new Error('Invalid chain id');
  const [chain, network] = moneriumChainId.split(':');
  return { chain, network } as { chain: ChainName; network: Network };
};

// as defined by Safe
export const chainShortName: Record<string, string> = {
  1: 'eth',
  100: 'gno',
  137: 'matic',
  200: 'eth',
  42161: 'arb1',
  11155111: 'sep',
  10200: 'chiado',
};

/**
 * @deprecated remove references
 */
export const getChainByMoneriumId = (
  moneriumChainId: ChainId | { chain: ChainName; network: Network },
): Chain | undefined => {
  let chainName = '';
  let networkName = '';

  if (typeof moneriumChainId === 'string') {
    const { chain, network } = splitMoneriumChainId(moneriumChainId);
    chainName = chain;
    networkName = network;
  } else {
    chainName = moneriumChainId.chain;
    networkName = moneriumChainId.network;
  }

  switch (chainName) {
    case 'arbitrum':
      if (networkName === 'mainnet') return arbitrum;
      if (networkName === 'sepolia') return arbitrumSepolia;
      return undefined;
    case 'avalanche':
      if (networkName === 'mainnet') return avalanche;
      if (networkName === 'fuji') return avalancheFuji;
      return undefined;
    case 'ethereum':
      if (networkName === 'mainnet') return mainnet;
      if (networkName === 'sepolia') return sepolia;
      if (networkName === 'local') return ethereumLocal;
      return undefined;
    case 'polygon':
      if (networkName === 'mainnet') return polygon;
      if (networkName === 'amoy') return polygonAmoy;
      return undefined;
    case 'gnosis':
      if (networkName === 'mainnet') return gnosis;
      if (networkName === 'chiado') return gnosisChiado;
      return undefined;
    default:
      return undefined;
  }
};

/**
 *
 * @deprecated use useChain hook
 */
export const getExplorerUrl = (
  chain: Chain | 'noble' | undefined,
  type: BlockExplorerUrlType,
  hash: string,
) => {
  /**
   * TODO: Noble support
   * Redo this function to just accept a chain name and lookup the relevant blockExplorer.
   * Also relevant to this function (this has been broken for a while):
   * When doing cross-chain txs and then linking to the txHash, the second txHash is always the counterpart
   * but we are currently treating all txHash as the same chain.
   * */
  let rootUrl;
  if (IS_PRODUCTION) {
    rootUrl = noble.blockExplorers?.default?.url;
  } else if (IS_SANDBOX) {
    rootUrl = nobleGrand.blockExplorers?.default?.url;
  } else {
    rootUrl = nobleFlorin.blockExplorers?.default?.url;
  }
  if (chain === 'noble') {
    const resourceType = type === 'address' ? 'account' : type;
    return `${rootUrl}/${resourceType}/${hash}`;
  }
  if (type === 'address' && hash?.includes('noble')) {
    return `${rootUrl}/account/${hash}`;
  }
  if (type === 'tx' && !hash?.startsWith('0x')) {
    return `${rootUrl}/tx/${hash}`;
  }

  if (!chain) return 'UNKNOWN';
  return `${
    chain?.blockExplorers?.etherscan?.url || chain?.blockExplorers?.default?.url
  }/${type}/${hash}`;
};

export const getTokenFilteredByAddressExplorerUrl = (
  chain: Chain | undefined,
  address: string,
  tokenAddress: string,
) => {
  if (chain?.id === 10200) {
    return `${
      chain.blockExplorers?.etherscan?.url ||
      chain?.blockExplorers?.default?.url
    }/address/${address}?tab=token_transfers&token=${tokenAddress}`;
  }
  return `${
    chain?.blockExplorers?.etherscan?.url || chain?.blockExplorers?.default?.url
  }/token/${tokenAddress}?a=${address}`;
};

/**
 *
 * @deprecated remove
 */
export const getDefaultChains = (): Chain[] => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location;
    if (
      hostname === 'localhost' &&
      window.App.isProxy &&
      (window.App.apiUrl === 'https://sandbox.monerium.dev' ||
        window.App.apiUrl === 'https://staging.monerium.dev')
    ) {
      return testnetChains;
    }
    if (
      hostname === 'localhost' &&
      window.App.isProxy &&
      window.App.apiUrl === 'https://monerium.app'
    ) {
      return productionChains;
    }
    if (hostname === 'localhost') {
      return localhostChains;
    }
    if (hostname.includes('monerium.dev')) {
      return testnetChains;
    }
  }
  return productionChains;
};
/**
 * @deprecated use useChain hook
 */
export const getChainByMoneriumChain = (
  chain: ChainName,
): Chain | undefined => {
  /**
   * TODO: Noble support
   * Clean this up
   */
  if (chain === 'noble') {
    if (IS_PRODUCTION) {
      return noble;
    }
    if (IS_SANDBOX) {
      return nobleGrand;
    }
    return nobleFlorin;
  }
  return getDefaultChains().find((c) => c.moneriumId?.chain === chain);
};
