// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./background.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--breakpoint-xs:320px;--breakpoint-sm:574px;--breakpoint-md:767px;--breakpoint-lg:990px;--breakpoint-xl:1199px;--breakpoint-max-height:600px}[data-radix-popper-content-wrapper]{-webkit-transform:none!important;-ms-transform:none!important;transform:none!important}.c54PN{background:rgba(0,0,0,.5);bottom:0;display:grid;height:100vh;left:0;overflow-y:auto;place-items:center;position:fixed;right:0;top:0;width:100vw;z-index:1300}.CgstM{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat top #fff;background-position:center -330px;border-radius:30px;min-height:700px;padding:4rem 2rem 2rem;position:relative;width:574px;width:var(--breakpoint-sm)}.Ydxtf{font-size:24px}.A8cuP,.Ydxtf{display:-ms-flexbox;display:flex;-ms-flex-pack:center;font-weight:600;justify-content:center}.A8cuP{font-size:18px}.BOw4u{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;margin:2rem}.LumAh{-webkit-appearance:none;-moz-appearance:none;appearance:none;background:transparent;border:none;font-family:inherit;font-size:14px;margin:0;outline:none;padding:0;position:absolute;right:32px;right:2rem;text-decoration:none;top:32px;top:2rem}.LumAh span{font-size:19.6px;font-size:1.225rem;font-weight:900}.LumAh:hover{cursor:pointer}@media (max-width:574px){.CgstM{border:none;border-radius:0;height:100vh;overflow:auto;width:100vw}}@-webkit-keyframes mN4QG{0%{opacity:0}to{opacity:1}}@keyframes mN4QG{0%{opacity:0}to{opacity:1}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `c54PN`,
	"content": `CgstM`,
	"title": `Ydxtf`,
	"description": `A8cuP`,
	"stepWrapper": `BOw4u`,
	"closeBtn": `LumAh`,
	"overlay-show": `mN4QG`
};
module.exports = ___CSS_LOADER_EXPORT___;
