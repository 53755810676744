import { getWalletIcon } from 'components/Wallet/icons';
import { useEffect, useState } from 'react';
import { Connector as WagmiConnector } from 'wagmi';
import Button from '../../Buttons/Button';

const WalletOption = ({
  connector,
  onClick,
}: {
  connector: WagmiConnector;
  onClick: () => void;
}) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      const provider = await connector.getProvider();

      setReady(!!provider);
    })();
  }, [connector]);

  const icon = getWalletIcon(connector.id) || connector?.icon;

  return (
    <Button disabled={!ready} onClick={onClick} icon={icon}>
      {connector.id === 'metaMask' || connector.id === 'injected'
        ? 'Browser wallet'
        : connector.name}
    </Button>
  );
};
export default WalletOption;
