// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._JVUg{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;width:50px}._JVUg .PWkP0{background-color:#ccc;border-radius:50%;height:8px;width:8px}._JVUg .PWkP0.Sd20x{background-color:#4b87dc}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper": `_JVUg`,
	"dot": `PWkP0`,
	"active": `Sd20x`
};
module.exports = ___CSS_LOADER_EXPORT___;
