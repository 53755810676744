import { useContext, useState } from 'react';
import { SignatureData } from '../types';
import { CosmosProviderContext, CosmosProviderContextType } from './Provider';

export const useCosmosProvider = (): CosmosProviderContextType => {
  const context = useContext(CosmosProviderContext);
  if (!context) {
    throw new Error(
      'useCosmosProvider must be used within a CosmosProviderProvider',
    );
  }
  return context;
};

export const useCosmosConnect = (): (() => Promise<void>) => {
  const context = useContext(CosmosProviderContext);
  if (!context) {
    throw new Error(
      'useCosmosProvider must be used within a CosmosProviderProvider',
    );
  }
  return context.connect;
};
export const useCosmosDisconnect = (): (() => void) => {
  const context = useContext(CosmosProviderContext);
  if (!context) {
    throw new Error(
      'useCosmosProvider must be used within a CosmosProviderProvider',
    );
  }
  return context.disconnect;
};

export const useCosmosSignMessage = (): {
  signatureData: SignatureData | undefined;
  signMessage: (message: string) => Promise<SignatureData | undefined>;
  error: unknown;
  isLoading: boolean;
} => {
  const context = useContext(CosmosProviderContext);
  if (!context) {
    throw new Error(
      'useCosmosProvider must be used within a CosmosProviderProvider',
    );
  }
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signatureData, setSignatureData] = useState<SignatureData | undefined>(
    undefined,
  );

  const signMessage = async (message: string) => {
    setSignatureData(undefined);
    setError(undefined);
    setIsLoading(true);
    try {
      const signature = await context.signMessage(message);
      setSignatureData(signature);
      return signature;
    } catch (e) {
      setError((e as Error)?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { signatureData, signMessage, error, isLoading };
};
