import { ButtonProps } from 'components/Button/types';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import SubmitButton from '../../Buttons/Submit';
import s from './Request.module.less';

const RequestSignature = ({
  handleSubmit,
}: {
  handleSubmit: ButtonProps['onClick'];
}) => {
  useStyles(s);
  return (
    <div className={s.signWrapper}>
      <span className={s.dialog}>
        You will be asked to sign a message with your wallet to prove that you
        are the real owner of this wallet.
      </span>
      <SubmitButton onClick={handleSubmit}>Sign</SubmitButton>
    </div>
  );
};
export default RequestSignature;
