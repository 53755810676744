export const shortenAddress = (a) =>
  a?.length > 20 ? `${a.substring(0, 6)}...${a.substring(a.length - 4)}` : a;

export const equal = (x, y) => x?.toLowerCase() === y?.toLowerCase();

export const isNull = (a) => {
  if (typeof a !== 'string') return true;
  if (!a || a.length <= 0) return true;
  const trim = a.replace(/[\s0xX]/gi, '');
  if (trim === '' || !trim) return true;
  return false;
};

export default {
  shortenAddress,
  equal,
  isNull,
};
