import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Stepper.module.less';

const StepperRoot = ({
  currentStep,
  activeSteps,
}: {
  currentStep: string;
  activeSteps: string[];
}) => {
  useStyles(s);

  const activeStep = activeSteps.indexOf(currentStep);

  return (
    <div className={s.stepper}>
      {activeSteps?.map((_st, i) => {
        return (
          <div
            key={i}
            className={cx(s.dot, { [s.active]: activeStep === i })}
          />
        );
      })}
    </div>
  );
};

export default StepperRoot;
