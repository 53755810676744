// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uSCsx{-webkit-column-gap:16px;-moz-column-gap:16px;column-gap:16px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;background-color:#fff;border:1px solid #edeef0;border-radius:15px;font-size:18px;font-weight:600;line-height:24px;padding:16px}.uSCsx:hover{background-color:#f8f9fd;cursor:pointer}.uSCsx:disabled{background-color:#f8f9fd;cursor:not-allowed}.uSCsx img{border-radius:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `uSCsx`
};
module.exports = ___CSS_LOADER_EXPORT___;
