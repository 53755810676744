// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hYmi3{-webkit-column-gap:8px;-moz-column-gap:8px;column-gap:8px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;background-color:#0f172a;border:1px solid #edeef0;border-radius:15px;color:#fff;font-size:18px;font-weight:600;line-height:24px;padding:16px;width:100%;-ms-flex-pack:center;justify-content:center}.hYmi3:hover{background-color:#0f172acc;border:1px solid #0f172a;cursor:pointer}.hYmi3 img{border-radius:8px}.Pfcrd{font-size:12px;font-weight:600;line-height:13px;padding:4px 16px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `hYmi3`,
	"small": `Pfcrd`
};
module.exports = ___CSS_LOADER_EXPORT___;
