import cx from 'classnames';
import { ButtonProps } from 'components/Button/types';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Submit.module.less';

const SubmitButton = (props: ButtonProps & { small?: boolean }) => {
  const { children, small = false, ...rest } = props;
  useStyles(s);
  return (
    <button
      type="submit"
      className={cx(s.button, { [s.small]: small })}
      {...rest}
    >
      {children}
    </button>
  );
};
export default SubmitButton;
