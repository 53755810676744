import { EcosystemId } from 'types/emoney/Chain/indexV2';
import { isAddress } from 'viem';

export const getKind = (address: string): EcosystemId | undefined => {
  if (address.startsWith('0x') && isAddress(address)) {
    return 'evm';
  } else if (address.startsWith('noble')) {
    return 'cosmos';
  } else {
    return undefined;
  }
};

export const isEvmAddress = (address?: string): boolean => {
  return !!address && getKind(address) === 'evm';
};
