// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OPSoo{border:1px solid #edeef0;border-radius:15px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;font-size:18px;font-weight:600;line-height:24px;padding:16px;row-gap:16px}.OPSoo .uPwWS{font-size:14px}.OPSoo .m9Og2{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-webkit-column-gap:8px;-moz-column-gap:8px;column-gap:8px}.OPSoo .m9Og2 .VtpeR{color:#333;font-size:24px}.OPSoo .m9Og2 img{border-radius:8px;height:42px;width:42px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryPanel": `OPSoo`,
	"title": `uPwWS`,
	"walletInfo": `m9Og2`,
	"address": `VtpeR`
};
module.exports = ___CSS_LOADER_EXPORT___;
