import * as RadixDialog from '@radix-ui/react-dialog';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { ReactNode } from 'react';
import s from './Modal.module.less';

const SignatureModalRoot = ({
  trigger,
  title,
  description,
  children,
  open,
  setOpen,
  onClose,
  stepper,
}: {
  trigger: ReactNode;
  children: ReactNode;
  title: string | ReactNode;
  description: string | ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  stepper?: ReactNode;
}) => {
  useStyles(s);

  return (
    <RadixDialog.Root open={open} onOpenChange={setOpen}>
      <RadixDialog.Trigger asChild>
        {trigger ? trigger : null}
      </RadixDialog.Trigger>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={s.overlay}>
          <RadixDialog.Content
            className={s.content}
            onPointerDownOutside={(e) => e.preventDefault()}
            onInteractOutside={(e) => e.preventDefault()}
          >
            <RadixDialog.Title className={s.title}>{title}</RadixDialog.Title>
            <div className={s.stepWrapper}>{stepper}</div>
            <RadixDialog.Description className={s.description}>
              {description}
            </RadixDialog.Description>
            {children}
            <RadixDialog.Close asChild>
              <button
                autoFocus
                className={s.closeBtn}
                aria-label="close"
                type="button"
                onClick={() => onClose()}
              >
                <span className="material-icons">close</span>
              </button>
            </RadixDialog.Close>
          </RadixDialog.Content>
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
export default SignatureModalRoot;
