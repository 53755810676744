import chains from 'components/Chain/chains';
import { Chain, EcosystemId } from 'types/emoney/Chain/indexV2';
import {
  useAccount as useWagmiAccount,
  useConnect as useWagmiConnect,
  useDisconnect as useWagmiDisconnect,
} from 'wagmi';
import { useCosmosProvider, useCosmosSignMessage } from './cosmos/hooks';
import { useEvmSignMessage } from './evm/hooks';
import { SignatureData } from './types';

export const useDisconnect = (kind?: EcosystemId) => {
  const { disconnect: disconnectCosmos } = useCosmosProvider();
  const { disconnect: disconnectEvm } = useWagmiDisconnect();

  const disconnect = () => {
    if (kind === 'cosmos') {
      // Disconnect from cosmos
      disconnectCosmos();
    } else if (kind === 'evm') {
      // Disconnect from evm
      disconnectEvm();
    } else if (!kind) {
      // Disconnect from all
      disconnectCosmos();
      disconnectEvm();
    }
    // 1s delay for side effects
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  return { disconnect };
};

export const useConnect = (kind: EcosystemId) => {
  const { connect: connectCosmos } = useCosmosProvider();
  const { connect: connectEvm } = useWagmiConnect();

  const connect = async ({ connector, chainId }, { onSuccess, onError }) => {
    if (kind === 'cosmos') {
      try {
        await connectCosmos();
        onSuccess();
      } catch (error) {
        onError(error);
      }
      return;
    } else {
      connectEvm(
        { connector, chainId },
        {
          onSuccess,
          onError,
        },
      );
      return;
    }
  };

  return { connect };
};

export const useConnected = (
  kind: EcosystemId,
): { address: string; chain: Chain } => {
  const { address: evmAddress, chainId: evmChainId } = useWagmiAccount();
  const { address: cosmosAddress, chainId: cosmosChainId } =
    useCosmosProvider();

  if (kind === 'cosmos') {
    return {
      address: cosmosAddress,
      chain: chains.find((c) => c.chainId === cosmosChainId) as Chain,
    };
  } else {
    return {
      address: evmAddress as string,
      chain: chains.find((c) => c.chainId === evmChainId) as Chain,
    };
  }
};

export const useSignMessage = (
  kind: EcosystemId,
): {
  signatureData?: SignatureData;
  signMessage: (message: string) => Promise<SignatureData | undefined>;
  isLoading: boolean;
  error: unknown;
} => {
  const {
    signature: evmSignature,
    signMessage: signEvmMessage,
    error: evmError,
    isLoading: evmIsLoading,
  } = useEvmSignMessage();
  const {
    signatureData: cosmosSignatureData,
    signMessage: signCosmosMessage,
    error: cosmosError,
    isLoading: cosmosIsLoading,
  } = useCosmosSignMessage();

  const signMessage = async (message: string) => {
    if (kind === 'cosmos') {
      return await signCosmosMessage(message);
    } else {
      return {
        signature: await signEvmMessage(message),
      };
    }
  };

  let signatureData;
  if (kind === 'cosmos') {
    if (cosmosSignatureData) {
      signatureData = cosmosSignatureData;
    }
  } else {
    if (evmSignature) {
      signatureData = {
        signature: evmSignature,
      };
    }
  }

  return {
    signatureData: signatureData,
    signMessage,
    isLoading: kind === 'cosmos' ? cosmosIsLoading : evmIsLoading,
    error: kind === 'cosmos' ? cosmosError : evmError,
  };
};
