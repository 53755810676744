import { ButtonProps } from 'components/Button/types';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import SubmitButton from '../../Buttons/Submit';
import s from './Troubleshoot.module.less';

const Troubleshoot = ({
  messageToSign,
  handleSubmit,
  handleReconnect,
}: {
  messageToSign: string;
  handleSubmit: ButtonProps['onClick'];
  handleReconnect: ButtonProps['onClick'];
}) => {
  useStyles(s);
  return (
    <div className={s.panelSummary}>
      <span className={s.title}>Continue in your wallet</span>
      <span className={s.dialog}>
        Please check your wallet, you should be prompted to sign a message{' '}
        <br />
        <br />“{messageToSign}” <br />
        <br />
        Sign this message with your wallet to complete adding your wallet to
        Monerium. <br />
        <br />
        <strong>Having trouble?</strong> <br />
        <br />
        If you’re not seeing the message in your wallet, here are some steps to
        try:
        <br />
        <div className={s.actions}>
          <SubmitButton small onClick={handleSubmit}>
            Try signing again
          </SubmitButton>
          <SubmitButton small onClick={handleReconnect}>
            Reconnect your wallet
          </SubmitButton>
        </div>
        If the issue persists, try clearing your browser’s cookies and site
        data, then refresh the page. You can also contact{' '}
        <a href="https://monerium.app/help">our support.</a>
      </span>
    </div>
  );
};
export default Troubleshoot;
