import { useCosmosProvider } from 'components/Wallet/cosmos/hooks';
import { useConnect } from 'components/Wallet/hooks';
import { getWalletIcon } from 'components/Wallet/icons';
import { useLayoutEffect } from 'react';
import { Chain, CosmosChainId } from 'types/emoney/Chain/indexV2';
import Button from '../../Buttons/Button';
import ButtonGroup from '../../Buttons/ButtonGroup';

const CosmosSelect = ({
  chain,
  handleSuccess,
}: {
  chain: Chain;
  handleSuccess: (wallet: string) => void;
}) => {
  const { connect } = useConnect('cosmos');
  const { isConnected: isKeplrConnected } = useCosmosProvider();

  const wallet = 'keplr';

  useLayoutEffect(() => {
    // No need display Keplr option if it's already connected.
    if (isKeplrConnected) {
      handleSuccess(wallet);
    }
  }, []);

  return (
    <>
      <ButtonGroup>
        <Button
          icon={getWalletIcon(wallet)}
          onClick={async () => {
            await connect(
              {
                connector: wallet,
                chainId: chain?.chainId as CosmosChainId,
              },
              {
                onSuccess: () => handleSuccess(wallet),
                onError: (e: Error) => {
                  console.error('Could not connect', e?.message);
                },
              },
            );
          }}
        >
          Keplr
        </Button>
      </ButtonGroup>
    </>
  );
};
export default CosmosSelect;
