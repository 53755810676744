import browserWalletIcon from 'assets/emoney/wallets/browserwallet.svg';
import coinbaseIcon from 'assets/emoney/wallets/coinbase.svg';
import keplrIcon from 'assets/emoney/wallets/keplr.svg';
import safeIcon from 'assets/emoney/wallets/safe.svg';
import walletConnectIcon from 'assets/emoney/wallets/walletconnect.svg';
import { Connector } from 'wagmi';

export const getWalletIcon = (id: Connector['id']) => {
  let icon;
  /** Inject connectors can retrieve icons */
  if (id === 'walletConnect') {
    icon = walletConnectIcon;
  } else if (id === 'safe') {
    icon = safeIcon;
  } else if (id === 'coinbaseWalletSDK') {
    icon = coinbaseIcon;
  } else if (id === 'metaMask' || id === 'injected') {
    icon = browserWalletIcon;
  } else if (id === 'keplr') {
    icon = keplrIcon;
  } else {
    icon = '';
  }
  return icon;
};
